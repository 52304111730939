<template>
  <div
    class="cart-product-section qa-Cart__products"
    :data-preload="[$options.name]"
  >
    <div class="cart-product-section__header header">
      <Icon name="car" class="header__icon" width="30" />
      <span class="header__text">{{ title }}</span>
    </div>

    <div class="cart-product-section__products products">
      <ProductRow
        v-for="product in products"
        :key="product.articleNumber"
        class="products__product"
        :class="{
          disabled:
            productMarkedForDeletion &&
            productMarkedForDeletion.sku === product.sku,
        }"
        :product="product"
        :is-kvi="product.isKvi"
        :actions="productActions"
        :is-readonly="isSharedCart"
        :is-cart="false"
        hide-net-price
        @quantity-change="
          (quantity) => $emit('quantity-change', { product, quantity })
        "
        @action="(event) => $emit('action', event)"
      />
    </div>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import Icon from 'Components/00-generated/Icon.vue';
import ProductRow from 'Components/02-molecules/product-row/ProductRow.vue';
import { mapState } from 'vuex';

export default {
  name: 'CartProductSection',
  components: { Icon, ProductRow },
  mixins: [globalMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    productMarkedForDeletion: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState('cart', ['isSharedCart']),
    productActions() {
      if (this.isSharedCart) {
        return [
          {
            type: 'wishlist',
            icon: 'wishlist_heart',
            class: 'qa-ProductTile__wishlist product-action--secondary',
          },
          {
            type: 'add-to-cart',
            icon: 'cart',
            class: 'qa-ProductTile__add-to-cart product-action--secondary',
          },
        ];
      }

      return [
        {
          type: 'wishlist',
          icon: 'wishlist_heart',
          class: 'qa-ProductTile__wishlist product-action--secondary',
        },
        {
          type: 'delete',
          icon: 'delete',
          class: 'qa-ProductTile__delete product-action--secondary',
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';

$section-border: 1px solid var(--color-similar-silver);

@mixin section-border(
  $border-color: var(--color-similar-silver),
  $border-width: 1px,
  $sides: [ 'top',
  'right',
  'bottom',
  'left' ]
) {
  @each $side in $sides {
    border-#{$side}: $border-width solid $border-color;
  }
}

.cart-product-section {
  width: 100%;
}

.cart-product-section__header {
  display: flex;
  align-items: center;
  padding: var(--space-1) var(--space-2) var(--space-0--half);

  @include section-border(var(--color-primary), 5px, [ 'top' ]);
  @include section-border(
    var(--color-similar-silver),
    1px,
    [ 'right',
    'bottom',
    'left' ]
  );
}

.header__icon {
  margin-right: var(--space-2);
}

.header__text {
  color: var(--color-rolling-stone);
  font-size: var(--font-size-S);
  line-height: var(--font-size-ML);
  font-weight: bold;
}

.products {
  @include section-border(
    var(--color-similar-silver),
    1px,
    [ 'right',
    'bottom',
    'left' ]
  );
}

::v-deep {
  .product-action--primary {
    background: var(--color-white);
  }
}

@media (min-width: 840px) {
  .products__product:not(:last-child) {
    ::v-deep .product-row__content:not(.product-row__content--with-deposit) {
      @include section-border(var(--color-similar-silver), 1px, [ 'bottom' ]);
    }
  }
}

.products__product.disabled {
  opacity: 0.2;
  pointer-events: none;
}
</style>
