var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-row",class:[_vm.classes, { 'product-row--compact': _vm.isCompact }],attrs:{"data-preload":[_vm.$options.name]}},[_c('div',{staticClass:"product-row__content content",class:{
      'product-row__content--with-deposit': _vm.prices.unit.deposit.length,
    }},[(_vm.product.images && _vm.product.images[0] && _vm.product.images[0].url)?_c('AdvancedImage',{staticClass:"content__product-image",attrs:{"src":_vm.product.images[0].url,"alt":_vm.product.images[0].alt || '',"config":{ q: 100, w: 100 }}}):_c('NoImageSvg',{staticClass:"content__product-image-no-image",attrs:{"lazy":true,"inline":false}}),_vm._v(" "),_c('div',{staticClass:"content__information information"},[_c('div',{staticClass:"information__brand qa-ProductTile__brand"},[_c('MaskedLink',{staticClass:"qa-ProductTile__tracking",attrs:{"redirect-to":_vm.product.productUrl,"text":_vm.product.brand}})],1),_vm._v(" "),_c('div',{staticClass:"information__title qa-ProductTile__title"},[_c('MaskedLink',{staticClass:"qa-ProductTile__tracking",attrs:{"redirect-to":_vm.product.productUrl,"text":_vm.product.name}})],1),_vm._v(" "),_c('div',{staticClass:"information__article"},[_vm._v("\n        "+_vm._s(_vm.$t('sku'))+" "+_vm._s(_vm.product.displaySku)+"\n      ")]),_vm._v(" "),_c('Availability',{staticClass:"information__availability qa-ProductTile__availabilty",attrs:{"availability-key":_vm.availabilityKey,"is-new-availability":_vm.isNewAvailability,"label":_vm.product && _vm.isNewAvailability
            ? ''
            : _vm.product.availability && _vm.product.availability.label}})],1),_vm._v(" "),_c('div',{staticClass:"content__numbers"},[_c('div',{staticClass:"content__unit-prices unit-prices qa-priceList__unit"},_vm._l((_vm.prices.unit.retail),function(price,key){return _c('Price',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNetPrice(price)),expression:"!isNetPrice(price)"}],key:key,staticClass:"unit-prices__price",class:{
            'unit-prices__price--large': key === 0,
            'unit-prices__price--discounted':
              _vm.product.isDiscountCodeApplied && key !== 0,
          },attrs:{"label":_vm.formatLabel(price, key),"value":`${_vm.normalizePrice(price.price, _vm.currency)}`}})}),1),_vm._v(" "),_c('div',{staticClass:"content__quantity quantity qa-ProductTile__quantity"},[(!_vm.isReadonly)?_c('QuantitySelector',{staticClass:"quantity__selector",attrs:{"label":_vm.$t('quantity'),"value":_vm.product.quantityAmount,"choices":_vm.product.quantityOptions,"threshold":_vm.product.quantityThreshold,"product":_vm.product,"is-cart-and-checkout":true,"select-class":"qa-ProductTile__wishlist--quantity"},on:{"onQuantityChange":({ quantity }) => _vm.$emit('quantity-change', quantity),"maxQuantityReached":_vm.handleMaxQuantityReached}}):_c('div',{staticClass:"quantity__selector--readonly"},[_c('input',{staticClass:"quantity__readonly-value",attrs:{"disabled":""},domProps:{"value":_vm.product.quantityAmount}})])],1),_vm._v(" "),_c('div',{staticClass:"content__total-prices total-prices"},_vm._l((_vm.prices.total.retail),function(price,key){return _c('Price',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNetPrice(price)),expression:"!isNetPrice(price)"}],key:key,staticClass:"total-prices__price",class:{
            'total-prices__price--bold total-prices__price--large': key === 0,
            'total-prices__price--discounted':
              _vm.product.isDiscountCodeApplied && key !== 0,
            'total-prices-price-is-kvi': _vm.product.isKvi,
          },attrs:{"label":_vm.formatLabel(price, key),"value":`${_vm.normalizePrice(price.price, _vm.currency)}`,"is-kvi":_vm.product.isKvi}})}),1),_vm._v(" "),(_vm.product.isDiscountCodeApplied)?_c('div',{staticClass:"content__discount-percentage",attrs:{"data-testid":"discount-percentage"}},[_vm._v("\n        "+_vm._s(_vm.$t('discount_percentage', {
            '%percentage%': _vm.product.discountCode.discountPercentage,
          }))+"\n      ")]):_vm._e()]),_vm._v(" "),(_vm.isMaxQuantityReached)?_c('div',{staticClass:"max-quantity-error"},[_c('Icon',{staticClass:"icon",attrs:{"name":"warning","width":"17"}}),_vm._v("\n      "+_vm._s(_vm.$t('max-quantity-error'))+"\n    ")],1):_vm._e(),_vm._v(" "),(_vm.prices.unit.deposit.length)?_c('div',{staticClass:"content__deposits deposits",class:[
        { 'content__deposits--discounted': _vm.product.isDiscountCodeApplied },
        { 'content__deposits--hidden': _vm.isCart },
      ],attrs:{"data-testid":"products-surcharge"}},_vm._l((_vm.prices.unit.deposit),function(price,index){return _c('div',{key:price.label,staticClass:"deposits__deposit deposit"},[_c('span',{staticClass:"deposit__label"},[_vm._v(_vm._s(price.label))]),_vm._v(" "),(price.price)?_c('span',{staticClass:"deposit__unit"},[_vm._v("\n          "+_vm._s(_vm.normalizePrice(price.price, _vm.currency))+"\n        ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"deposit__quantity"},[_vm._v("\n          "+_vm._s(_vm.$t('pieces', { '%amount%': _vm.product.quantityAmount }))+"\n        ")]),_vm._v(" "),(_vm.prices.total.deposit[index].price)?_c('span',{staticClass:"deposit__total"},[_vm._v("\n          "+_vm._s(_vm.normalizePrice(_vm.prices.total.deposit[index].price, _vm.currency))+"\n        ")]):_vm._e()])}),0):_vm._e()],1),_vm._v(" "),_c('TileActions',{attrs:{"actions-list":{ actions: _vm.processedActions },"handle-actions":false},on:{"action":_vm.handleAction}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }