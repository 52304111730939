<i18n src="./translations"></i18n>

<template>
  <div class="voucher-dialog" :data-preload="[$options.name]">
    <Button
      class="voucher-dialog__VoucherTrigger qa-VoucherTrigger__button"
      data-testid="voucher-trigger"
      modifier="secondary"
      :title="$t('label')"
      :on-click="showVoucherDialog"
    />

    <Dialog
      ref="dialog"
      class="voucher-dialog"
      is-voucher
      click-outside
      :title="$t('title')"
      :active="isActive"
      :is-rebranding-channel="isRebrandingChannel"
    >
      <form @submit.prevent="addVoucherCode">
        <Input
          v-model="code"
          class="voucher-dialog__input"
          data-testid="voucher-input"
          :form="inputForm"
          type="text"
        />
        <div class="buttons">
          <Button
            :title="$t('cancel')"
            :modifiers="['secondary', isRebrandingChannel ? 'rebranding' : '']"
            class="cancel-button"
            type="button"
            :on-click="closeDialog"
          />
          <Button
            :title="$t('accept')"
            :modifiers="['primary', isRebrandingChannel ? 'rebranding' : '']"
            class="confirm-button"
            data-testid="voucher-confirm-button"
            type="submit"
          />
        </div>
      </form>
    </Dialog>
  </div>
</template>

<script>
import Button from 'Components/01-atoms/button/Button.vue';
import Dialog from 'Components/02-molecules/dialog/Dialog.vue';
import Input from 'Components/01-atoms/form/input/Input.vue';
import globalMixin from 'Libs/mixins/globalMixin';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'VoucherDialog',
  components: {
    Button,
    Dialog,
    Input,
  },
  mixins: [globalMixin],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      code: '',
      errorMessage: [],
    };
  },
  computed: {
    ...mapGetters('core', ['isRebrandingChannel']),
    inputForm() {
      return {
        vars: {
          label: this.$t('label'),
          value: this.code,
          required: true,
          errors: this.errorMessage,
          valid: this.errorMessage.length > 0,
        },
      };
    },
  },
  methods: {
    ...mapActions('cart', ['addDiscountCode']),
    async addVoucherCode() {
      if (!this.code) {
        return;
      }

      const response = await this.addDiscountCode({
        cartId: this.cartId,
        discountCode: this.code,
        source: 'cart',
      });

      if (response?.messages[0].type === 'success') {
        this.closeDialog();
      }

      if (response?.messages[0].type === 'error') {
        this.errorMessage = [
          {
            message: response?.messages[0].text,
          },
        ];
      }
    },
    showVoucherDialog() {
      this.$refs.dialog.show();
    },
    closeDialog() {
      this.code = '';
      this.errorMessage = [];
      this.$refs.dialog.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';

.voucher-dialog {
  margin-bottom: var(--space-2);
}

.voucher-dialog__VoucherTrigger {
  padding: var(--space-0--half) var(--space-4);
  font-size: var(--font-size-S);
  text-transform: none !important;
  width: 100%;
}

.voucher-dialog__input {
  padding-top: var(--space-1);
}

::v-deep .button {
  font-size: var(--font-size-S) !important;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: var(--space-2) 0 var(--space-2) 0;

  .button:last-of-type {
    margin-left: var(--space-1);
  }
}

@media #{$_mediaSDown} {
  .voucher-dialog {
    width: 100%;
    font-size: var(--font-size-S);
  }

  .voucher-dialog__VoucherTrigger {
    width: 100%;
    font-size: var(--font-size-S);
  }

  .buttons {
    flex-direction: column-reverse;

    .button:last-of-type {
      margin-left: 0;
      margin-bottom: var(--space-1);
    }

    ::v-deep article .body {
      flex-grow: 0;
    }
  }
}
</style>
