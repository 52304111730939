<i18n src="./translations"></i18n>

<template>
  <div class="cart-header" :data-preload="[$options.name]">
    <span class="cart-header__title">
      {{ isSharedCart ? $t('shared_title') : $t('title') }}
    </span>

    <template v-if="totalProductCount">
      <div class="cart-header__buttons">
        <Button
          class="cart-header__share-button qa-Cart__share"
          modifier="secondary"
          :title="$t('share')"
          icon="paper_plane"
          :on-click="showCartShareDialog"
        />
        <Button
          class="cart-header__checkout-button qa-Cart__cart"
          modifier="primary"
          :title="$t('checkout')"
          :href="frontFacingPath('checkout.checkout.details')"
        />

        <ShareLinkDialog
          class="cart-header__share-dialog"
          :active="dialogState.share"
          :title="$t('share_cart.title')"
          type="cart"
          :link-id="cartId"
          @hide="hideCartShareDialog"
        />
      </div>
    </template>

    <div class="usp-list">
      <div class="usp-item">
        <span class="usp-wrapper">
          <ReturnSvg class="usp-icon" />
        </span>
        <p class="usp-text">
          {{ $t('usp_messages.return-window') }}
        </p>
      </div>
      <div class="usp-item">
        <span class="usp-wrapper">
          <ShippingSvg class="usp-icon" />
        </span>
        <p class="usp-text">
          {{
            $t('usp_messages.shipping', {
              '%freeDeliveryMin%': freeDeliveryMin,
            })
          }}
        </p>
      </div>
      <div class="usp-item usp-item-mobile">
        <span class="usp-wrapper">
          <SparePartsSvg class="usp-icon" />
        </span>
        <p class="usp-text">
          {{ $t('usp_messages.count') }}
        </p>
      </div>
    </div>

    <span v-if="isSharedCart" class="cart-header-shared_text">
      {{ $t('shared_text') }}
    </span>
  </div>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';
import Button from 'Components/01-atoms/button/Button.vue';
import ShareLinkDialog from 'Components/02-molecules/share-link-dialog/ShareLinkDialog.vue';
import ReturnSvg from 'Components/00-generated/ReturnSvg';
import ShippingSvg from 'Components/00-generated/ShippingSvg';
import SparePartsSvg from 'Components/00-generated/SparePartsSvg';
import { mapState } from 'vuex';

export default {
  name: 'CartHeader',
  components: {
    Button,
    ShareLinkDialog,
    ReturnSvg,
    ShippingSvg,
    SparePartsSvg,
  },
  mixins: [globalMixin],
  props: {
    totalProductCount: {
      type: Number,
      default: 0,
    },
    cartId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialogState: { share: false },
    };
  },
  computed: {
    ...mapState('core', {
      freeDeliveryMin: ({ freeDeliveryMin }) => freeDeliveryMin,
    }),
    ...mapState('cart', ['isSharedCart']),
  },
  methods: {
    showCartShareDialog() {
      this.dialogState.share = true;
    },
    hideCartShareDialog() {
      this.dialogState.share = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';

.cart-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: var(--space-1--half);
}

.cart-header__title {
  color: var(--color-nero);
  font-weight: 700;
  font-size: var(--font-size-XL);
  margin: var(--space-0--half) var(--space-1) var(--space-0--half) 0;
}

.cart-header-shared_text {
  margin: var(--space-1--half) 0 var(--space-1) 0;
  font-weight: 700;
  font-size: var(--font-size-S);
  color: var(--color-mine-shaft);
  line-height: var(--font-size-M);
}

.cart-header__buttons {
  display: flex;
  margin: var(--space-1) 0;
}

.cart-header__share-button {
  margin: var(--space-0--half);
  min-width: 250px;
  text-transform: none !important;
}

.cart-header__checkout-button {
  margin: var(--space-0--half);
  min-width: 250px;
  text-transform: none !important;
}

.usp-list {
  display: flex;
  align-items: center;
  background: var(--color-wild-sandish);
  border-radius: 3px;
  width: 100%;
  margin-top: var(--space-2);
  margin-bottom: var(--space-4);
  padding: var(--space-2--half) var(--space-3);
}

.usp-item {
  display: flex;
  align-items: center;
}

.usp-wrapper {
  margin-right: var(--space-1--half);
}

.usp-icon {
  fill: var(--color-hippo-darker);
  height: var(--space-4);
  width: var(--space-4);
}

.usp-text {
  font-weight: 700;
  color: var(--color-nero);
  font-size: var(--space-2);
  line-height: var(--space-2--half);
  margin-right: var(--space-6);
}

@media #{$_mediaSDown} {
  .cart-header {
    flex-wrap: wrap;
  }

  .cart-header__title {
    flex-grow: 99;
  }

  .cart-header__buttons {
    margin: var(--space-1) -6px;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .cart-header__share-button {
    display: none !important;
  }

  .cart-header__checkout-button {
    margin: var(--space-1);
  }
}

@media #{$_mediaMDown} {
  .cart-header__buttons {
    flex-flow: wrap-reverse;
    max-width: calc(100% + 12px);
    flex-grow: 1;
  }

  .cart-header__share-button,
  .cart-header__checkout-button {
    flex-grow: 1;
  }

  .usp-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .usp-item-mobile {
    display: none;
  }

  .usp-item {
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: var(--space-2--half);
    }
  }
}
</style>
