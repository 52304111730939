<i18n src="./translations"></i18n>

<template>
  <div class="cart" :data-preload="[$options.name]">
    <CartHeader
      :total-product-count="totalProductCount"
      :cart-id="cartId"
      data-testid="CartHeader"
    />
    <template v-if="totalProductCount">
      <CartProductSection
        v-for="(section, index) of sections"
        :key="section.vehicle"
        data-testid="CartProductSection"
        class="cart__product-section"
        :class="{ 'cart__product-section--without-margin': index === 0 }"
        :title="section.vehicle"
        :product-actions="[]"
        :products="section.products"
        :product-marked-for-deletion="productMarkedForDeletion"
        @quantity-change="handleQuantityChange"
        @action="handleAction"
      />
      <div class="cart-footer">
        <CartAmounts
          :amounts="amounts"
          :dropshipping-items="dropshippingItems"
          :cart-id="cartId"
          :voucher="voucher"
          is-not-confirmation-step
          data-testid="CartAmounts"
        />

        <div
          v-if="!!paymentMethods.length || !!deliveryMethods.length"
          class="cart-footer__services"
        >
          <FooterServices
            v-if="!!paymentMethods.length"
            type="payment"
            :title="$t('accepted_payments')"
            :providers="paymentMethods"
          />

          <FooterServices
            v-if="!!deliveryMethods.length"
            type="delivery"
            :title="$t('shipping_providers')"
            :providers="deliveryMethods"
          />
        </div>
      </div>
    </template>

    <template v-else>
      <div class="cart-footer__empty" data-testid="Cart__empty">
        <span class="cart-footer__emptyText qa-Cart__emptyText">
          {{ $t('empty_text') }}
        </span>

        <div class="cart-footer__emptyButton" data-testid="Cart__emptyButton">
          <Button
            class="emptyButton-inner"
            modifier="primary"
            :title="$t('home')"
            :href="frontFacingPath('content.page.node_id.0')"
          />
        </div>
      </div>
    </template>

    <Dialog
      ref="deleteDialog"
      :title="$t('delete-dialog.title')"
      class="confirm-delete-dialog"
      :confirm-button="{
        title: this.$t('delete-dialog.confirm-delete'),
        onClick: () => confirmDelete(),
      }"
      :cancel-button="{
        title: this.$t('delete-dialog.cancel'),
      }"
      @hide="cancelDialog()"
    >
      <div class="dialog_content">
        <p>{{ $t('delete-dialog.content') }}</p>
      </div>
    </Dialog>
  </div>
</template>

<script>
import CartHeader from 'Components/02-molecules/cart-header/CartHeader.vue';
import CartProductSection from 'Components/02-molecules/cart-product-section/CartProductSection.vue';
import CartAmounts from 'Components/02-molecules/cart-amounts/CartAmounts.vue';
import Button from 'Components/01-atoms/button/Button.vue';
import Dialog from 'Components/02-molecules/dialog/Dialog.vue';
import FooterServices from 'Components/02-molecules/footer-services/FooterServices';
import globalMixin from 'Libs/mixins/globalMixin';
import { mapGetters, mapState, mapActions } from 'vuex';

export default {
  name: 'Cart',
  components: {
    CartHeader,
    CartProductSection,
    Button,
    FooterServices,
    CartAmounts,
    Dialog,
  },
  mixins: [globalMixin],
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      productMarkedForDeletion: null,
      isDeleteLoading: false,
    };
  },
  computed: {
    ...mapGetters('core', { isBusinessCustomer: 'isBusinessCustomer' }),
    ...mapState('cart', [
      'cartId',
      'voucher',
      'totalProductCount',
      'sections',
      'amounts',
      'dropshippingItems',
    ]),
    ...mapState('core', {
      paymentMethods({ paymentMethods }) {
        const methods = paymentMethods.map((icon) => ({
          icon,
          title: this.$t(`payment.${icon}`),
        }));

        return this.isBusinessCustomer ? [methods[0]] : methods;
      },
      deliveryMethods({ deliveryMethods }) {
        return deliveryMethods.map((icon) => ({
          icon,
          title: this.$t(`delivery.${icon}`),
        }));
      },
    }),
  },
  methods: {
    ...mapActions('miniCart', ['addToCart']),
    ...mapActions('cart', ['updateQuantity', 'updateWishlist']),
    handleAction({ type, product }) {
      if (type === 'wishlist') {
        this.updateWishlist({
          product,
          list: 'Cart',
        });
      }
      if (type === 'add-to-cart') {
        this.addToCart({
          product: {
            sku: product.sku,
            carTypeNumber: product.carTypeNumber,
            productId: product.productId,
            amount: product.quantityAmount,
          },
          quantity: product.quantityAmount,
        });
      } else if (type === 'delete') {
        this.productMarkedForDeletion = product;
        this.$refs.deleteDialog.show();
      }
    },
    async confirmDelete() {
      const product = this.productMarkedForDeletion;
      this.isDeleteLoading = true;

      await this.updateQuantity({
        product,
        amount: 0,
      });

      this.productMarkedForDeletion = null;
      this.isDeleteLoading = false;
    },
    cancelDialog() {
      if (this.isDeleteLoading) return;

      this.productMarkedForDeletion = null;
    },
    handleQuantityChange({ product, quantity }) {
      this.updateQuantity({
        product,
        amount: quantity,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';

.cart {
  width: 100%;
}

.cart-header {
  width: 100%;
}

.cart-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-top: var(--space-4);
}

.cart-footer__empty {
  padding: var(--space-4);
  border: 1px solid var(--color-alto);
  min-height: 150px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cart-footer__emptyText {
  font-weight: 700;
  color: var(--color-mine-shaft);
  line-height: 26px;
}

.cart-footer__emptyButton {
  margin: 0 -8px;
}

.cart-footer__services {
  width: 100%;
}

.cart__empty {
  padding: var(--space-4);
  border: 1px solid var(--color-alto);
  min-height: 150px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cart__empty-text {
  font-weight: 700;
  color: var(--color-mine-shaft);
  line-height: 26px;
}

@media #{$_mediaSDown} {
  .cart-footer {
    justify-content: center;
  }
}
</style>
