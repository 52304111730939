var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{ref:"shareLinkDialog",staticClass:"share-link-dialog qa-TheDialog__inner",attrs:{"title":_vm.title},on:{"hide":_vm.closeDialog}},[_c('div',{staticClass:"content"},[_c('span',{staticClass:"message"},[_vm._v(" "+_vm._s(_vm.$t('message')))]),_vm._v(" "),_c('div',{staticClass:"share-input"},[_c('Input',{staticClass:"share-link-dialog__link",attrs:{"form":{
          vars: {
            full_name: 'share-link',
            readonly: true,
            value: _vm.shareLink,
          },
        },"type":"text"}}),_vm._v(" "),_c('Button',{staticClass:"share-link-dialog__copy-button",attrs:{"data-testid":"copy-share-link","title":_vm.$t('copy'),"modifier":"primary","on-click":_vm.copyShareLink}}),_vm._v(" "),_c('Button',{staticClass:"share-link-dialog__mail-button",attrs:{"modifier":"secondary","icon":"email","href":_vm.emailHref}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }