<i18n src="./translations"></i18n>

<template>
  <Dialog
    ref="shareLinkDialog"
    class="share-link-dialog qa-TheDialog__inner"
    :title="title"
    @hide="closeDialog"
  >
    <div class="content">
      <span class="message"> {{ $t('message') }}</span>
      <div class="share-input">
        <Input
          class="share-link-dialog__link"
          :form="{
            vars: {
              full_name: 'share-link',
              readonly: true,
              value: shareLink,
            },
          }"
          type="text"
        />
        <Button
          class="share-link-dialog__copy-button"
          data-testid="copy-share-link"
          :title="$t('copy')"
          modifier="primary"
          :on-click="copyShareLink"
        />
        <Button
          class="share-link-dialog__mail-button"
          modifier="secondary"
          icon="email"
          :href="emailHref"
        />
      </div>
    </div>
  </Dialog>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

import Input from 'Components/01-atoms/form/input/Input.vue';
import Dialog from 'Components/02-molecules/dialog/Dialog.vue';
import Button from 'Components/01-atoms/button/Button.vue';
import { mapActions } from 'vuex';

export default {
  name: 'ShareLinkDialog',
  components: { Dialog, Input, Button },
  mixins: [globalMixin],
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    linkId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      emailHref: 'mailto:?subject=' + this.$t('email_subject') + '&body=@body',
      shareLink: '',
    };
  },
  watch: {
    active() {
      if (this.active === true) {
        this.fetchShareLink();
        this.$refs.shareLinkDialog.show();
      } else {
        this.$refs.shareLinkDialog.hide();
      }
    },
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    closeDialog() {
      this.$emit('hide');
    },
    ...mapActions({
      getShareLink(dispatch, payload) {
        return dispatch(this.type + '/getShareLink', payload);
      },
    }),
    async copyShareLink() {
      await navigator.clipboard.writeText(this.shareLink);
      this.addMessage({
        text: this.$t('link_copied'),
        key: 'share-link-dialog.link_copied',
      });
      this.closeDialog();
    },
    async fetchShareLink() {
      const response = await this.getShareLink();

      if (response?.shareLink) {
        this.shareLink = response.shareLink;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'variables/breakpoints';

.content {
  display: flex;
  flex-wrap: wrap;

  .message {
    width: 100%;
    margin-bottom: var(--space-1--half);
    font-size: var(--font-size-SM);
  }

  .share-input {
    display: flex;
    width: 100%;
  }
}

.share-link-dialog {
  &__link {
    flex-basis: 100%;
    flex-grow: 1;

    input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: 0 !important;
    }
  }

  &__copy-button {
    margin-right: var(--space-2);
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &__mail-button {
    &:hover {
      path {
        fill: var(--color-white) !important;
      }
    }

    path {
      fill: var(--color-primary) !important;
    }
  }
}

::v-deep {
  .inputWrapper .inputEl {
    padding: var(--space-1) var(--space-2);
    font-size: var(--font-size-S);
  }
}
</style>
