import { render, staticRenderFns } from "./CartAmounts.vue?vue&type=template&id=2775c3fd&scoped=true"
import script from "./CartAmounts.vue?vue&type=script&lang=js"
export * from "./CartAmounts.vue?vue&type=script&lang=js"
import style0 from "./CartAmounts.vue?vue&type=style&index=0&id=2775c3fd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2775c3fd",
  null
  
)

/* custom blocks */
import block0 from "./translations?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fdata%2Fwww%2Fsrc%2Fcomponents%2F02-molecules%2Fcart-amounts%2FCartAmounts.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports