
<template>
  <!-- eslint-disable -->
  <svg :style="style" :class="classes" data-components="ShippingSvg" xmlns="http://www.w3.org/2000/svg" data-name="01_Atoms/Icons/shipping_success" viewBox="0 0 35.795 23.13"><path fill="none" d="M0 0h35.997v23.395H0z" data-name="bounding box"/><path d="M5.352-.066v3.035h6.375v1.217H0v1.638h11.727v1.211H1.832v1.463h3.52v6.9h-.916V17.2h2.02l1.257 1.98a4.178 4.178 0 004.168 4.019 4.179 4.179 0 004.14-3.663h5.65l1.3-1.13h1.846l.703 1.13h.601a4.177 4.177 0 004.143 3.663 4.177 4.177 0 004.139-3.663h1.392v-1.377h-.132l-.004.002v-4.955c0-2.75-2.167-11.12-3.414-11.12H26.03V-.067H5.352zM27.622 3.8h3.314c.293 0 .59.234.713.502.451 1 .795 2.256.993 2.951.186.658.782 3.11.878 4.008.025.293-.094.47-.416.447-2.538-.17-4.96-.818-5.498-.973-.407-.116-.517-.309-.517-.664V4.334c0-.293.24-.533.533-.533zm-15.74 12.828a2.4 2.4 0 012.397 2.396 2.4 2.4 0 01-2.397 2.395 2.397 2.397 0 01-2.394-2.395 2.398 2.398 0 012.394-2.396zm18.377 0a2.397 2.397 0 012.394 2.396c0 1.32-1.073 2.395-2.394 2.395s-2.397-1.075-2.397-2.395a2.4 2.4 0 012.397-2.396z"/></svg>
</template>

<script>
import globalMixin from 'Libs/mixins/globalMixin';

export default {
  name: 'ShippingSvg',
  mixins: [globalMixin],
  props: {
    width: {
      type: [String, Number, Boolean],
      default: false
    },
    height: {
      type: [String, Number, Boolean],
      default: false
    },
    original: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      const style = {};

      if (this.width) {
        style.width = this.width + 'px';
      }

      if (this.height) {
        style.height = this.height + 'px';
      }

      return style;
    }
  }
};
</script>
